import "../styles/blog.scss"

import { Link, graphql, navigate } from "gatsby"
import React, { Component } from "react"
import {
  categoriesStringToSlugsArray,
  formatDate,
} from "../components/helpers/helpers"
import {
  getAllArticles,
  searchFields,
  searchInCollection,
} from "../components/helpers/searchHelpers"
import { getParamsFromQs, getTranslations } from "../components/helpers/helpers"

import Layout from "../components/layout"
import SEO from "../components/seo"
import i18Data from "../../content/intl/labels.json"

const PerPage = 20

class Blog extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pageNumber: 1,
      allArticles: [],
    }

    this.clearSearch = this.clearSearch.bind(this)
  }

  componentDidMount() {
    const {
      pageContext: { lang },
    } = this.props

    getAllArticles(lang)
      .then(response => {
        this.setState({
          allArticles: response.data,
        })
      })
      .catch(error => {
        console.error(error)
      })
  }

  loadMore() {
    this.setState({ pageNumber: this.state.pageNumber + 1 })
  }

  clearSearch(ev) {
    if (!ev.target.value) navigate(this.props.path)
  }

  render() {
    const {
      data: { pageData, blogPosts, arrunadaIlustration },
      pageContext: { lang, prefix, tag, slug },
      location,
    } = this.props

    const parms = getParamsFromQs(
      location.search ? location.search.substring(1).split("&") : [],
      { q: "" },
      true
    )

    const stringQ = decodeURI(parms.q)

    const page = pageData.edges.filter(
      ({ node }) => node.frontmatter.lang === lang
    )[0]?.node
    const translations = getTranslations(pageData.edges, "blog")
    const tagSlug = categoriesStringToSlugsArray([tag])[0].slug
    const i18 = i18Data[lang]

    const searchSlugs = parms.q
      ? searchInCollection(this.state.allArticles, searchFields, stringQ)
      : []

    const blogPostsFiltered = parms.q
      ? blogPosts.edges.filter(
          ({ node }) => searchSlugs.indexOf(node.frontmatter.slug) >= 0
        )
      : !tagSlug
      ? blogPosts.edges
      : blogPosts.edges.filter(({ node }) =>
          categoriesStringToSlugsArray(node.frontmatter.categories).some(
            c => c.slug === tagSlug
          )
        )

    const limitN = this.state.pageNumber * PerPage
    const showMore = limitN < blogPostsFiltered.length

    return (
      <Layout
        mainClassName="blog"
        data={{}}
        noActiveMenu={true}
        pageContext={this.props.pageContext}
        translations={translations}
      >
        <SEO
          title={page.frontmatter.title}
          description={page.frontmatter.summary}
          lang={lang ? lang : "en"}
        />

        <div
          className="blog-container initial-padding"
          style={{
            backgroundImage: `url(${arrunadaIlustration.childImageSharp.fluid.srcWebp})`,
          }}
        >
          <div className="blog-top">
            <h1
              dangerouslySetInnerHTML={{ __html: page.frontmatter.summary }}
            ></h1>

            {!!tag && <h2>{`${i18.labels.category}: ${tag}`}</h2>}
            {!!parms.q && <h2>{`${i18.labels.search}: ${stringQ}`}</h2>}
          </div>

          <div className="blog-search">
            <form
              onSubmit={event => {
                event.preventDefault()
                navigate(`?q=${this.searchTextInput.value}`)
              }}
            >
              <input
                name="searchText"
                type="search"
                placeholder={i18.labels.searchBox}
                aria-label={i18.labels.searchBox}
                onChange={this.clearSearch}
                ref={t => {
                  this.searchTextInput = t
                }}
              />
              <button className="search-go" aria-label={i18.labels.searchGo}>
                <span className="big-link"></span>
              </button>
            </form>
          </div>

          <div className="blog-articles">
            {blogPostsFiltered
              .filter((_, idx) => idx < limitN)
              .map(({ node }) => {
                const url = `${prefix}/${slug}/${node.frontmatter.slug}`

                return (
                  <article
                    key={node.frontmatter.translateKey}
                    id={node.frontmatter.translateKey}
                    className="post hentry"
                  >
                    <header className="entry-header">
                      <div className="entry-meta">
                        <time
                          className="entry-date"
                          data-datetime={node.frontmatter.pubDate}
                          pubdate=""
                        >
                          {formatDate(
                            new Date(node.frontmatter.pubDate),
                            lang,
                            false
                          )}
                        </time>
                      </div>
                      <h1 className="entry-title">
                        <Link rel="bookmark" to={url}>
                          {node.frontmatter.title}
                        </Link>
                      </h1>
                    </header>

                    <div className="entry-summary">
                      {node.frontmatter.summary || node.excerpt}
                    </div>

                    <footer className="entry-meta">
                      <span className="cat-links">
                        {categoriesStringToSlugsArray(
                          node.frontmatter.categories
                        ).map(cat => {
                          return (
                            <Link
                              key={cat.slug}
                              to={`${prefix}/${slug}/tag/${cat.slug}`}
                              rel="category tag"
                            >
                              {cat.text}
                            </Link>
                          )
                        })}
                      </span>
                    </footer>
                  </article>
                )
              })}

            <span className="meta-nav middle-line"></span>
            {showMore && (
              <span
                aria-hidden="true"
                className="load-more"
                onClick={this.loadMore.bind(this)}
              >
                {i18.labels.loadMore}
              </span>
            )}
          </div>
        </div>
      </Layout>
    )
  }
}

export default Blog

export const query = graphql`
  query BlogQuery($lang: String!) {
    arrunadaIlustration: file(
      relativePath: { eq: "arrunada-illustration-bw.jpg" }
    ) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    pageData: allMarkdownRemark(
      filter: { frontmatter: { template: { eq: "blog" } } }
    ) {
      edges {
        node {
          frontmatter {
            lang
            template
            slug
            summary
            title
            translateKey
          }
          id
          html
        }
      }
    }
    blogPosts: allMarkdownRemark(
      filter: {
        frontmatter: { lang: { eq: $lang }, template: { eq: "blogPost" } }
      }
      sort: { order: DESC, fields: frontmatter___pubDate }
    ) {
      edges {
        node {
          frontmatter {
            lang
            template
            slug
            summary
            title
            pubDate
            categories
            translateKey
          }
          excerpt
        }
      }
    }
  }
`
