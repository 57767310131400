import axios from "axios"

export const searchFields = [
  "slug",
  "categories",
  "summary",
  "title",
  "excerpt",
]
export const jsonFilename = lang => `/static/blogPosts_${lang}.json`

export const getTermsFromString = str => {
  const strFx = str
    .replace(/[,\s]+/, " ")
    .replace(/[áà]/gi, "a")
    .replace(/[éè]/gi, "e")
    .replace(/[íì]/gi, "i")
    .replace(/[óò]/gi, "o")
    .replace(/[úù]/gi, "u")
  const terms = strFx
    .split(" ")
    .filter(s => !!s)
    .map(s => String(s).toLowerCase())
  return terms
}

export const searchInCollection = (collection, objKeys = [], str) => {
  let terms = getTermsFromString(str),
    matches = []

  collection.forEach(obj => {
    let fulltext = objKeys
      .map(f => obj[f])
      .join(" ")
      .toLowerCase()
    fulltext = fulltext
      .replace(/&([aeiou])[acute|grave]+;/gi, "$1")
      .replace(/[áà]/gi, "a")
      .replace(/[éè]/gi, "e")
      .replace(/[íì]/gi, "i")
      .replace(/[óò]/gi, "o")
      .replace(/[úù]/gi, "u")

    let match = terms.every(word => fulltext.indexOf(word) > -1)

    if (match) {
      matches.push(obj)
    }
  })

  return matches.map(s => s.slug)
}

export const getAllArticles = lang => {
  return new Promise((resolve, reject) => {
    axios
      .get(jsonFilename(lang))
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  })
}
